"use client";
import React from "react";
import type { NextPage } from "next";
import HeaderPhoto from "../../../public/Header.png";
import Header2 from "../../../public/Headersmall.png";
import { Oswald, Roboto } from "next/font/google";
import Image from "next/image";
import Link from "next/link";

const oswalds = Oswald({ subsets: ["latin"] });
const roboto = Roboto({ weight: ["300"], subsets: ["latin"] });

interface Heading {
  head: string;
}

interface HeadingProps {
  heading: Heading;
}

const LoginSignupLeft: NextPage<HeadingProps> = ({ heading }) => {
  return (
    <div
      className="w-screen h-full md:flex items-center hidden"
      style={{
        backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${HeaderPhoto.src})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="py-[16px] bg-cover bg-center bg-no-repeat max-w-full h-full w-1/2 header-container flex">
        <div className="w-full flex flex-col justify-center items-center gap-[24px]">
          <a href="/">
            <Image
              src="/Logo.png"
              alt="logo"
              width={250}
              height={120}
              className="w-[250px] h-[120px]"
            />
          </a>
          <div className="">
            <h1 className="font-roboto font-weight:400 text-[1rem] leading-[120%] max-[368px]:text-[0.7rem] text-white">
              Price may will be higher or lower than face value
            </h1>
          </div>

          <div className="flex">
            <div className="flex flex-col justify-center items-center gap-[16px] px-4 ">
              <div className="flex flex-col gap-[16px]">
                {/* <div className='flex justify-center'>
                                    <div className="px-8 py-4 bg-white bg-opacity-10 rounded-lg border-2 border-indigo-300 backdrop-blur-[18.40px] justify-center items-center gap-2.5 inline-flex">
                                        <div className={`text-white text-5xl font-normal leading-[57.60px] ${oswalds.className}`}>{heading.head}</div>
                                    </div>
                                </div> */}

                <div
                  className={`md:flex items-center justify-center flex-col gap-8 hidden ${roboto.className}`}
                >
                  <div className="grid md:grid-cols-1 xl:grid-cols-2 gap-8">
                    <div className="w-full bg-blue-950 py-2 px-6 flex flex-col justify-center items-center gap-2">
                      <div className="flex items-end gap-1">
                        {/* <Image
                          className="w-6 h-6 relative"
                          alt=""
                          width={6}
                          height={6}
                          src="/tick.svg"
                        /> */}
                        <div className="text-white text-19px font-bold leading-22.8">
                          Have a Question ?
                        </div>
                      </div>
                      <div className="flex flex-col items-center text-white text-base font-normal leading-tight">
                        {/* <p>Please Read our FAQ's Page to</p>
                        <p>assist you especially after placing</p>
                        <p>an order</p> */}
                        <p>Please Read our <Link href="/faq"><span className="underline italic">FAQ's page</span></Link></p>
                      </div>
                    </div>

                    <div className="w-full bg-blue-950 p-2 flex flex-col justify-start items-center gap-22">
                      <div className="h-70px flex flex-col justify-center items-center gap-2">
                        <div className="flex items-center gap-1">
                          {/* <img
                            className="w-6 h-6 relative"
                            alt=""
                            src="/tick.svg"
                          /> */}
                          <div className="text-white text-19px font-bold leading-22.8">
                            Customer Care
                          </div>
                        </div>
                        <div className="flex flex-col items-center text-white text-base font-normal leading-tight">
                          <p>7 days a week 9:30 am to 10 pm </p>
                          <p> EST visit our <Link href="/contactus"><span className="underline italic"> Contact Us</span></Link> page to</p>
                          <p>connect</p>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}

                    {/* <div className=" flex items-start gap-8"> */}
                    <div className="w-full bg-blue-950 p-2 flex flex-col justify-center items-center gap-2">
                      <div className="flex items-start gap-1">
                        {/* <img
                          className="w-6 h-6 relative"
                          alt=""
                          src="/tick.svg"
                        /> */}
                        <div className="text-white text-19px font-bold leading-22.8">
                          Valid Seats
                        </div>
                      </div>
                      <div className="flex flex-col items-center text-white text-base font-normal leading-tight">
                        <p>All listed tickets are valid and ready</p>
                        <p>to be shipped on the assigned</p>
                        <p>delivery date</p>
                      </div>
                    </div>

                    <div className="w-full p-2 bg-blue-950 flex flex-col justify-center items-center gap-2">
                      <div className="flex items-end gap-1">
                        <div className="w-6 h-6 relative"></div>
                        {/* <img
                          className="w-6 h-6 relative"
                          alt=""
                          src="/tick.svg"
                        /> */}
                        <div className="text-white text-19px font-bold leading-22.8">
                          Full Refund
                        </div>
                      </div>
                      <div className="flex flex-col items-center text-white text-base font-normal leading-tight text-justify">
                        <p>On all cancelled events</p>
                        <p>Please read our <Link href="/faq"><span className="underline italic">FAQ's page</span></Link> for more details.</p>
                      </div>
                    </div>
                  </div>

                  <div className="text-white text-19px font-normal leading-22.8 text-center">
                    For all website order questions, please contact Customer
                    Care @ <a href="tel:4373702833">437-370-2833</a>
                    <br />
                    Make sure to leave a detailed message and include your Order
                    Number if you have one.
                  </div>

                </div>

                <div
                  className={`md:hidden flex flex-col items-center text-[18px] text-white font-medium leading-[120%] max-[356px]:text-[14px] max-[300px]:text-[14px] ${oswalds.className}`}
                >
                  <h1>YOUR PASSPORT TO SPORTS,</h1>
                  <h1>CONCERTS, THEATRE, AND BEYOND</h1>
                </div>
                <div className="flex w-full-200%">
                  {/* <div className='relative flex items-center w-full-200% h-10 z-[1] rounded-lg focus-within:shadow-lg bg-white overflow-hidden max-md:h-8'>
                                        <IoSearch className='h-6 w-6 m-3' />
                                        <input type='text' className="h-full w-full outline-none text-sm text-gray-700 max-md:text-[13px] max-md:w-[270px] max-[348px]:w-[300px] " placeholder='Search by teams, artist, event or venue' />
                                    </div> */}
                </div>
              </div>

              {/* <p className='text-white mb-[24px]'>For all website order questions, please contact Customer Care @ 437-370-2833
                            Make sure to leave a detailed message and include your Order Number if you have one.</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginSignupLeft;
